import React from 'react';
import {Tooltip} from "@chakra-ui/tooltip";
import ProductSerialNumber from "../entities/product/SerialNumber";
import CustomerInternalId from "../entities/customer/CustomerInternalId";
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Importer le module pour localiser les dates en français
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.locale('fr'); // Définir la locale en français pour dayjs
dayjs.extend(localizedFormat)

type ValueType = string | number | null | Date;

interface Props {
    value: ValueType;
    attributeKey: string;
}

//return a react component
interface Handler {
    setNext(handler: Handler): Handler;

    handleRequest(input: string, key?:string): JSX.Element;
}


class DateHandler implements Handler {
    private nextHandler: Handler;

    setNext(handler: Handler): Handler {
        this.nextHandler = handler;
        return handler;
    }

    handleRequest(input: string, key:string): JSX.Element {
        // check for this format 2022-07-12 15:48:08.977+0000
        const postgresPattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.?\d{3}.\d{4}$/g;

        const iso8601Pattern = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/g;

        if (Number(input) > 648559237000 && Number(input) < 3173167237000) { // Unix timestamp should be greater than Jan 1, 2000
                const date = dayjs.unix(Number(input)/1000); // Parse the Unix timestamp using dayjs
                const formattedDate = this.formatDate(date);
                return <span>{formattedDate}</span>;
        } else if (iso8601Pattern.test(input)) {
            const date = dayjs(input); // Parse the ISO 8601 date using dayjs
            if (date.isValid()) {
                const formattedDate = this.formatDate(date);
                return <span>{formattedDate}</span>;
            }
        } else if (postgresPattern.test(input)) {
            const date = dayjs(input); // Parse the ISO 8601 date using dayjs
            if (date.isValid()) {
                const formattedDate = this.formatDate(date);
                return <span>{formattedDate}</span>;
            }
        }

        // If no valid date format found, pass the input to the next handler
        if (this.nextHandler) {
            return this.nextHandler.handleRequest(input, key);
        } else {
            return <span>N/A</span>;
        }
    }

    private formatDate(date: dayjs.Dayjs): string {
        // Custom date formatting, you can change this based on your requirements
        const formattedDate = date.format("LLL");
        return formattedDate;
    }
}


// class URLHandler implements Handler {
//     private nextHandler: Handler;
//
//     setNext(handler: Handler): Handler {
//         this.nextHandler = handler;
//         return handler;
//     }
//
//     handleRequest(input: string, key:string=null): JSX.Element {
//         const urlPattern = /^(http:\/\/|https:\/\/)(www\.)?([a-zA-Z0-9]+)\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?/;
//         if (urlPattern.test(input)) {
//             return (<a href={input} target="_blank" rel="noreferrer">{input}</a>);
//         } else if (this.nextHandler) {
//             return this.nextHandler.handleRequest(input,key);
//         } else {
//             return (<>N/A</>);
//
//         }
//     }
// }

class ImageHandler implements Handler {
    private nextHandler: Handler;

    setNext(handler: Handler): Handler {
        this.nextHandler = handler;
        return handler;
    }

    handleRequest(input: string, key:string=null): JSX.Element {
        const urlPattern = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

        if( /^\/\//.test(input)){
            input = `https://${input.replace(/^\/\//, '')}`;

        }


        if (urlPattern.test(input)) {
            return (<img src={input} alt="" className="w-24 h-24"></img>)
        } else if (this.nextHandler) {
            return this.nextHandler.handleRequest(input,key);
        } else {
            return (<>N/A</>);

        }
    }
}


// class IDHandler implements Handler {
//     private nextHandler: Handler;
//
//     setNext(handler: Handler): Handler {
//         this.nextHandler = handler;
//         return handler;
//     }
//
//     handleRequest(input: string, key:string=null): JSX.Element {
//         const idPattern = /^[0-9]+$/;
//         if (idPattern.test(input)) {
//             return (<span><b>{input}</b></span>)
//         } else if (this.nextHandler) {
//             return this.nextHandler.handleRequest(input,key);
//         } else {
//             return (<>N/A</>);
//
//
//         }
//     }
// }

class StringHandler implements Handler {
    private nextHandler: Handler;

    setNext(handler: Handler): Handler {
        this.nextHandler = handler;
        return handler;
    }

    handleRequest(input: string, key:string=null): JSX.Element {
        // sim data usage in bytes to mb
        if(key === "ctdDataUsage"){
            return (<span className="font-thin text-black-900 whitespace-pre-line "><b>{(Number(input)/1000000).toFixed(2)} MB</b></span>)
        }

        // sim voice usage in seconds to minutes
        if(key === "ctdVoiceUsage"){
            return (<span className="font-thin text-black-900 whitespace-pre-line "><b>{(Number(input)/60).toFixed(2)} Mins</b></span>)

        }

        // check if its a string
        if (typeof input === 'string') {
            return (<span className="font-thin text-black-900 whitespace-pre-line "><b>{input}</b></span>)

        } else if (this.nextHandler) {
            return this.nextHandler.handleRequest(input,key);
        } else {
            return (<>N/A</>);


        }
    }
}


class ObjectHandler implements Handler {
    private nextHandler: Handler;

    setNext(handler: Handler): Handler {
        this.nextHandler = handler;
        return handler;
    }

    handleRequest(input: string, key:string=null): JSX.Element {
        try {
            const obj = JSON.parse(input);
            if (typeof obj === 'object') {
                return (<>...</>);

            } else if (this.nextHandler) {
                return this.nextHandler.handleRequest(input,key);
            } else {
                return (<>N/A</>);

            }
        } catch {
            if (this.nextHandler) {
                return this.nextHandler.handleRequest(input,key);
            } else {
                return (<>N/A</>);


            }
        }
    }
}

// class NumberHandler implements Handler {
//     private nextHandler: Handler;
//
//     setNext(handler: Handler): Handler {
//         this.nextHandler = handler;
//         return handler;
//     }
//
//     handleRequest(input: string, key:string=null): JSX.Element {
//         if (!isNaN(Number(input))) {
//             return (<span><b>{input}</b></span>)
//         } else if (this.nextHandler) {
//             return this.nextHandler.handleRequest(input,key);
//         } else {
//             return (<>N/A</>);
//
//         }
//     }
// }

class SerialNumberHandler implements Handler {
    private nextHandler: Handler;

    setNext(handler: Handler): Handler {
        this.nextHandler = handler;
        return handler;
    }

    handleRequest(input: string, key:string=null): JSX.Element {
        const idPattern = /^\d{1}-\d{4}-\d{4}-\d{4}$/;

        if (idPattern.test(input)) {
            return (<ProductSerialNumber productSN={input} />)
        } else if (this.nextHandler) {
            return this.nextHandler.handleRequest(input,key);
        } else {
            return (<span>N/A</span>);

        }
    }
}



class CustomerInternalIdHandler implements Handler {
    private nextHandler: Handler;

    setNext(handler: Handler): Handler {
        this.nextHandler = handler;
        return handler;
    }

    handleRequest(input: string, key:string=null): JSX.Element {
        if (key === 'netsuiteInternalId') {
            return (<CustomerInternalId internalId={input}/>)
        } else if (this.nextHandler) {
            return this.nextHandler.handleRequest(input, key);
        } else {
            return (<span>N/A</span>);

        }
    }
}


const ValueRenderer: React.FC<Props> = ({ value, attributeKey  }) => {
    const dateHandler = new DateHandler();
    const objectHandler = new ObjectHandler();
    const stringHandler = new StringHandler();
    const serialNumberHandler = new SerialNumberHandler();
    const customerInternalIdHandler = new CustomerInternalIdHandler()

    //** Chain of Responsibility Pattern */
    // 1. Create a chain of handler objects
    // 2. Set the chain of handlers
    // 3. Start the request
    dateHandler
        .setNext(new ImageHandler())
        .setNext(customerInternalIdHandler)
        .setNext(objectHandler)
        .setNext(serialNumberHandler)
        .setNext(stringHandler)

    let handlerChain = dateHandler;

    return (
        <div>
            {/*tooltip to show the original value:*/}
            <Tooltip
                placement="top"
                label={value as string}
                className={`w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none`}
            >
                {handlerChain.handleRequest(value as string, attributeKey as string)  }

            </Tooltip>

        </div>
    );
};

export default ValueRenderer;
