import React, { ChangeEvent, useEffect, useState } from "react"
import { InteractionRequiredAuthError } from "@azure/msal-browser"

import InputField from "components/fields/InputField"
import api from "../../api"
import MSALObj from "../../libs/msal"


export default function SignIn() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)

  // check if the user is already logged in with azure
  useEffect(() => {
    const accounts = MSALObj.getAllAccounts()

    if (accounts.length > 0) {
      azureLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLoginSuccess = (token: string) => {
    // save access token in local storage
    localStorage.setItem('token', token)

    console.log(token)

    // Redirect to the product page
    window.location.href = '/admin/products'
  }

  const azureLogin = () => {
    const loginRequest = {
      scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
      redirectUrl: `${process.env.REACT_APP_AZURE_REDIRECT_URL}`,
    }

    MSALObj.loginPopup(loginRequest)
      .then(loginResponse => {
        onLoginSuccess(loginResponse.accessToken)

      })
      .catch(error => {
        console.error(error)
        if (error instanceof InteractionRequiredAuthError) {
          return MSALObj.acquireTokenPopup(loginRequest)
            .then(tokenResponse => {
              onLoginSuccess(tokenResponse.accessToken)
            })
        }
      })
  }

  // Rest of the SignIn component
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      const response = await api.post('/login', {
        username,
        password,
      })

      // Assuming the server returns a token
      const token = response.data.token
      onLoginSuccess(token)
    } catch (error) {
      setError('Login failed. Please try again.')
    }
  }

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your username and password to sign in!
        </p>
        {/* Username */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="John Doe"
          id="username"
          value={username}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          type="text"
        />
        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          type="password"
        />
        {/* Checkbox */}
        {/*<div className="mb-4 flex items-center justify-between px-2">*/}
        {/*  <div className="flex items-center">*/}
        {/*    <Checkbox />*/}
        {/*    <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">*/}
        {/*      Keep me logged In*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*  <a*/}
        {/*    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"*/}
        {/*    href=" "*/}
        {/*  >*/}
        {/*    Forgot Password?*/}
        {/*  </a>*/}
        {/*</div>*/}
        <button
          onClick={handleLogin}
          type={"submit"}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>

        {/*Login with azure button*/}
        <button
          onClick={azureLogin}
          type={"submit"}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Login with Azure
        </button>

        {/*<div className="mt-4">*/}
        {/*  <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">*/}
        {/*    Not registered yet?*/}
        {/*  </span>*/}
        {/*  <a*/}
        {/*    href=" "*/}
        {/*    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"*/}
        {/*  >*/}
        {/*    Create an account*/}
        {/*  </a>*/}
        {/*</div>*/}
        {error && (<div className="flex items-center small-gap mt-6">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline ">{error}</span>
          </div>
        </div>)}
      </div>
    </div>
  )
}
