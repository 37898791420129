import React from 'react'

interface DateTimeProps {
    unformattedDatetime: any // Input datetime string (e.g., "2023-09-19T15:30:00")
}

export const DateTime: React.FC<DateTimeProps> = ({ unformattedDatetime }) => {
    // Convert the input datetime string to a JavaScript Date object
    const date = new Date(unformattedDatetime)

    // Create a French locale date and time formatter
    const formatter = new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    })
    // Format the date and time
    const formattedDateTime = formatter.format(date)
    return <span>{formattedDateTime}</span>
}
