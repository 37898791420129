import React, { useEffect, useMemo, useState } from 'react'
import { MRT_ColumnDef } from 'material-react-table'

import { KitsService } from '../../../services/KitsService'
import DatatableWrapper from '../../../components/list/DatatableWrapper'
import { Kit } from '../../../models'
import DashboardDatatable from '../../../components/dataTable/DashboardDatatable'
import { DateTime } from '../../../components/dateTime/DateTime'

const KitsOverview: React.FC = () => {
    const [kitsList, setKitsList] = useState<Kit[]>([])
    const [isSearching, setIsSearching] = React.useState<boolean>(true)

    const SerialNumberPUCell = (value: any) => {
        const serialNumber = value.row?.original?.serialNumberPU ?? 'N/A'

        return (
            <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleSerialNumberClick(serialNumber as string)}
            >
                {value.renderedCellValue}
            </span>
        )
    }

    const SerialNumberCell = (value: any) => {
        const serialNumber = value.row?.original?.serialNumber ?? 'N/A'

        return (
            <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleSerialNumberClick(serialNumber as string)}
            >
                {value.renderedCellValue}
            </span>
        )
    }

    const columns = useMemo<MRT_ColumnDef<Kit>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'serialNumber',
                header: 'Serial Number',
                Cell: SerialNumberCell,
            },
            {
                accessorKey: 'productNumberPU',
                header: 'Product Number PU',
            },
            {
                accessorKey: 'operator',
                header: 'Operator',
            },
            {
                accessorKey: 'status',
                header: 'Status',
            },
            {
                accessorKey: 'serialNumberPU',
                header: 'Serial Number PU',
                Cell: SerialNumberPUCell,
            },
            {
                accessorKey: 'createdAt',
                header: 'Created at',
                Cell: (value) => (
                    <DateTime
                        unformattedDatetime={value.row?.original?.createdAt}
                    />
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    useEffect(() => {
        setIsSearching(true)

        fetchData().then(() => {
            setIsSearching(false)
        })
    }, [])

    const fetchData = async () => {
        try {
            const kitsService: KitsService = new KitsService()
            const kits: Kit[] = await kitsService.getAll()

            setKitsList(kits ?? [])
        } catch (error) {
            console.error('Failed to fetch kits:', error)
        }
    }

    const handleSerialNumberClick = (serialNumber: string) => {
        // go to the page product details
        window.location.href = `/admin/product/${serialNumber}`
    }

    return (
        <div className="container mx-auto mt-6">
            <DatatableWrapper
                name={'Kits'}
                isLoading={isSearching}
                data={kitsList}
            >
                <DashboardDatatable
                    columns={columns}
                    data={kitsList}
                    name="Kits"
                />
            </DatatableWrapper>
        </div>
    )
}

export default KitsOverview
