import React, { useEffect, useMemo, useState } from 'react'
import { MRT_ColumnDef } from 'material-react-table'

import { Product } from '../../../models'
import { ProductService } from '../../../services/ProductService'
import mainApi from '../../../apis/mainApi'
import DatatableWrapper from '../../../components/list/DatatableWrapper'
import DashboardDatatable from '../../../components/dataTable/DashboardDatatable'
import { DateTime } from '../../../components/dateTime/DateTime'

const ProductsOverview: React.FC = () => {
    const SerialNumberCell = (value: any) => {
        const serialNumber = value.row?.original?.SerialNumber ?? 'N/A'

        return (
            <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleSerialNumberClick(serialNumber as string)}
            >
                {value.renderedCellValue}
            </span>
        )
    }

    const columns = useMemo<MRT_ColumnDef<Product>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'SerialNumber',
                header: 'Serial Number',
                Cell: SerialNumberCell,
            },
            {
                accessorKey: 'productNumber',
                header: 'Product Number',
            },
            {
                accessorKey: 'operator',
                header: 'Operator',
            },
            {
                accessorKey: 'productedAt',
                header: 'Produced at',
                enableGlobalFilter: false,
                Cell: (value) => (
                    <DateTime
                        unformattedDatetime={
                            value.row?.original?.productedAt as string
                        }
                    />
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const [productList, setProductList] = useState<Product[]>([])

    const [isSearching, setIsSearching] = React.useState<boolean>(true)
    const [searchedText] = React.useState<string>('')


    useEffect(() => {
        setIsSearching(true)

        fetchData().then(() => {
            setIsSearching(false)
        })
    }, [])

    const fetchData = async () => {
        try {
            const productService: ProductService = new ProductService(mainApi)
            const products: Product[] = await productService.getAll()

            setProductList(products)
        } catch (error) {
            console.error('Failed to fetch products:', error)
        }
    }

    const handleSerialNumberClick = (serialNumber: string) => {
        // go to the page product details
        window.location.href = `/admin/product/${serialNumber}`
    }

    const getSerialNumberType = (serialNumber: string) => {
        // if it starts with 0 then it is an UT
        if (serialNumber.startsWith('0')) {
            return 'UT'
        }

        // if it starts with 1 then it is a Camera
        if (serialNumber.startsWith('1')) {
            return 'Camera'
        }
    }

    return (
        <div className="container mx-auto mt-6">
            <div className={'my-6 flex items-center justify-between'}>
                {searchedText && !isSearching && (
                    <h1 className="text-center text-2xl">
                        Search results for the{' '}
                        {getSerialNumberType(searchedText)}/s : "{searchedText}"
                    </h1>
                )}
            </div>

            <DatatableWrapper
                name={'Products'}
                isLoading={isSearching}
                data={productList}
            >
                <DashboardDatatable
                    columns={columns}
                    data={productList}
                    name="Products"
                />
            </DatatableWrapper>
        </div>
    )
}

export default ProductsOverview
