import React from "react";

/**
 * Appends a rounded-2xl, white background, and shadow-3xl to the children.
 */
interface CardWrapperProps {
 children: React.ReactNode;
 isNested?: boolean;
 extra?: string;
}


const CardWrapper:React.FC<CardWrapperProps> = ({children, isNested=false, extra=''}) => {
    return (
        <>
            <div className={`${isNested? 'bg-blue-100' : ''} rounded-[20px] bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none ${extra}`}>
                {children}
            </div>
        </>
    );
};

export default CardWrapper;
