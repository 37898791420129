import CameraIcon from '@mui/icons-material/Camera'
import LogoutIcon from '@mui/icons-material/Logout'
import BusinessIcon from '@mui/icons-material/Business'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'

import SignIn from "views/auth/SignIn"
import Logout from "./views/auth/Logout"
import ProductsOverview from "./views/admin/products"
import ProductOverview from "./views/admin/product"
import CustomersOverview from "./views/admin/customers"
import CustomerOverview from "./views/admin/customer"
import KitsOverview from "./views/admin/kits"

const routes = [
  {
    name: "Product",
    layout: "/admin",
    path: "product/:id",
    icon: <CameraIcon className="h-6 w-6" />,
    component: <ProductOverview />,
    secondary: true,
  },
  {
    name: "Customer",
    layout: "/admin",
    path: "customers/:id",
    icon: <BusinessIcon className="h-6 w-6" />,
    component: <CustomerOverview />,
    secondary: true,
  },
  {
    name: "Products",
    layout: "/admin",
    path: "products",
    icon: <CameraIcon className="h-6 w-6" />,
    component: <ProductsOverview />,
    secondary: false,
  },
  {
    name: "Kits",
    layout: "/admin",
    path: "kits",
    icon: <BusinessCenterIcon className="h-6 w-6" />,
    component: <KitsOverview />,
    secondary: false,
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "customers",
    icon: <BusinessIcon className="h-6 w-6" />,
    component: <CustomersOverview />,
    secondary: false,
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "sign-in",
    icon: <CameraIcon className="h-6 w-6" />,
    component: <SignIn />,
    secondary: true,
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "logout",
    icon: <LogoutIcon className="h-6 w-6" />,
    component: <Logout />,
    secondary: true,
  },
]

export default routes
