import { AxiosInstance } from "axios"
import { Product, ProductConfig, SimInfo } from "../models"


export class ProductService {
    private readonly pathName = 'product'
    private readonly adminPathName = 'admin/product'
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    async getAll(): Promise<Product[] | null> {
        try {
            const response = await this.api.get(this.adminPathName)
            return response.data
        } catch (error) {
            console.error('Error fetching products data:', error)
            return null
        }
    }

    async getConfig(sn: string): Promise<ProductConfig | null> {
        try {
            const response = await this.api.get(this.pathName + `/${sn}/config`)
            const product = response.data as ProductConfig

            return product
        } catch (error) {
            console.error('Error fetching product data:', error)
            return null
        }
    }

    async getSimInfo(sn: string): Promise<SimInfo> {
        return this.api.get(this.pathName + `/${sn}/sim`).then(response => {
            return response.data as SimInfo
        })
    }

    async simEnable(sn: string, value: boolean): Promise<string | null> {
        try {
            const response = await this.api.post(this.pathName + `/${sn}/sim`, {
                enable: value
            })
            return response.data
        } catch (error) {
            console.error('Error fetching product data:', error)
            return null
        }
    }

    async getBySN(sn: string): Promise<Product | null> {
        try {
            const response = await this.api.get(this.adminPathName + `/${sn}`)
            const product = response.data as Product
            return product
        } catch (error) {
            console.error('Error fetching product data:', error)
            return null
        }
    }
}
