import React from "react"

interface Props {
    productSN: string
}

const ProductSerialNumber: React.FC<Props> = ({ productSN }) => {
    const handleSerialNumberClick = (serialNumber: string) => {
        // go to the page product details
        window.location.href = `/admin/product/${productSN}`
    }

    return (
        <span className="cursor-pointer text-blue-500"
            onClick={() => handleSerialNumberClick(productSN)}>
            {productSN}
        </span>
    )
}

export default ProductSerialNumber
