import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios"

const api = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
})

// intercept any error responses from the api, if the token is expired, redirect to the login page
api.interceptors.response.use(
    (res: AxiosResponse) => res,
    (err: AxiosError) => {
        if (err.response.status === 401) {
            localStorage.removeItem('token')
            window.location.href = '/auth/sign-in'
        }
        return Promise.reject(err)
    }
)


// Add a request interceptor
api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // Get the token from local storage
        const token = localStorage.getItem('token')

        // Set the token as the Authorization header
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    (error: AxiosError) => {
        return Promise.reject(error)
    }
)

export default api