import React from 'react'

interface EmptyStateProps {
    title?: string
    subtitle?: string
}

const EmptyState: React.FC<EmptyStateProps> = ({ title, subtitle }) => {
    return (
        <div className="flex flex-col items-center justify-center h-32 dark:bg-darkPrimary">
            <h2 className="text-lg font-bold mb-2 text-white">{title}</h2>
            <p className="text-white">{subtitle}</p>
        </div>
    )
}

EmptyState.defaultProps = {
    title: "No results found.",
    subtitle: "This list is empty.",
}

export default EmptyState
