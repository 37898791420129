import React from "react";

interface Props {
    internalId: string
}


const ProductSerialNumber: React.FC<Props>  = ({internalId}) => {
    const handleSerialNumberClick = (serialNumber: string) => {
        // go to the page product details
        window.location.href = `/admin/customers/${internalId}`;
    };

  return (
      <span
          className="cursor-pointer text-blue-500"
          onClick={() => handleSerialNumberClick(internalId)}
      >
                      {internalId}
                    </span>

  );
}

export default ProductSerialNumber;
