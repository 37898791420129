import React, { FC, useMemo } from 'react'
import { CircularProgress, createTheme } from "@mui/material"
import { ThemeProvider } from '@mui/material/styles'

interface Props {
    // Define your component's props here
    name: string
    isLoading: boolean
    data: any[]
    children: React.ReactNode
}

const DatatableWrapper: FC<Props> = ({ children, name, isLoading, data }) => {

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: "dark",
                    background: {
                        default: "rgb(5, 13, 29)",
                    },
                },
            }),
        [],
    )

    if (isLoading)
        return (
            <div className="flex items-center justify-center">
                <CircularProgress disableShrink />
            </div>)


    // Component logic and JSX rendering
    return (
        <div>
            <ThemeProvider theme={tableTheme}>
                {children}
            </ThemeProvider>
        </div>
    )
}

export default DatatableWrapper