import React, {useState} from 'react';
import {ExpandLess, ExpandMore} from "@mui/icons-material";

interface FoldableContainerProps {
    title: string;
    onClick: () => void;
    children: React.ReactNode;
}

const FoldableContainer: React.FC<FoldableContainerProps> = ({title, children, onClick}) => {
    const [isFolded, setIsFolded] = useState(true);

    const handleToggleFold = () => {
        setIsFolded(!isFolded);
        onClick();
    };

    return (
        <div className="mb-7">
            <div
                className="cursor-pointer flex items-center "
                onClick={handleToggleFold}
            >
                <p className="text-sm text-gray-600 font-bold flex">

                    {/*append a chevron icon here*/}
                    <div className="mr-2" style={{position: "relative", top: "-3px"}}>
                        {isFolded ? (
                            <ExpandMore className="text-gray-600"/>
                        ) : (
                            <ExpandLess className="text-gray-600"/>)}
                    </div>
                    {title}
                </p>

            </div>
            {!isFolded && (<div>{children}
                    <hr className="mt-2"/>
                </div>
            )

            }

        </div>
    );
};

export default FoldableContainer;
