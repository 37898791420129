import { BaseService } from "./BaseService"
import mainApi from "../apis/mainApi"
import { Customer } from "../models/Customer"
import { Kit } from "../models"

export class KitsService extends BaseService<Customer>{
    override readonly pathName = 'kits'
    private customerApi

    constructor() {
        super(mainApi)
        this.customerApi = mainApi
    }

    async getAll(): Promise<Customer[] | null> {
        let res = await super.getAll() as any

        if (res.kits)
            return res.kits
    }


    async getItemFulfillment(internalId: string): Promise<any | null> {
        try {
            const response = await this.customerApi.post('/item-fulfillment',
                {
                    internalId: internalId
                })

            return response.data
        } catch (error) {
            console.error(`Error fetching '${this.pathName}' data:`, error)
            return null
        }
    }

    async getBySN(sn: string): Promise<Kit | null> {
        try {
            const response = await this.api.post(this.pathName, {
                serialNumber: sn,
            })
            const kit = response.data as Kit
            return kit
        } catch (error) {
            console.error('Error fetching kit data:', error)
            return null
        }
    }

    async getByCustomerInternalId(customerInternalId: string): Promise<Kit[] | null> {
        try {
            const response = await this.customerApi.get(this.pathName + `?customerInternalId=${customerInternalId}`)

            return response.data.kits
        } catch (error) {
            console.error(`Error fetching '${this.pathName}' data:`, error)
            return null
        }
    }
}
