import React from 'react';

interface IniViewerProps {
    iniContent: string;
}

const IniViewer: React.FC<IniViewerProps> = ({iniContent}) => {
    return (
        <div
            style={{
                maxHeight: '300px',
                whiteSpace: 'pre-wrap',
                overflowY: 'auto',
                border: '1px solid #ccc',
                padding: '10px',
                fontFamily: 'monospace',
            }}
            dangerouslySetInnerHTML={{__html: iniContent}}
        />
    );
};

export default IniViewer;
