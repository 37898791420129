import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"

import EntityDetailsWidget from "../../../components/dashboard/widgets/EntityDetailsWidget"
import Widget from "../../../components/dashboard/widgets/Widget"
import CardWrapper from "../../../components/card/CardWrapper"
import SimpleList from "../../../components/list/SimpleList"
import EmptyState from "../../../components/list/EmptyState"
import { CustomersService } from "../../../services/CustomersService"
import { Kit } from "../../../models"
import { KitsService } from "../../../services/KitsService"
import Button from "../../../components/buttons/Button"
import Spinner from "../../../components/spinner/Spinner"


const CustomerOverview = () => {
    const navigate = useNavigate()
    // search bar input status
    const { id } = useParams<{ id: string }>()
    const [isSearching, setIsSearching] = React.useState<boolean>(false)
    const [customer, setCustomer] = React.useState<any>({})
    const [customerKits, setCustomerKits] = React.useState<Kit[]>([])

    const [isFetchingItems, setIsFetchingItems] = React.useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            setIsSearching(true)

            const customerService = new CustomersService()
            let customerRes = null

            try {
                customerRes = await customerService.getByInternalId(id)
            } catch (e) {
                setIsSearching(false)
                return
            }

            setCustomer(customerRes.customer)
            setIsSearching(false)

            let getCustomerItems = null
            setIsFetchingItems(true)

            try {
                getCustomerItems = await customerService.getCustomerItems(id)
            } catch (e) {
                setIsFetchingItems(false)
                return
            }

            setCustomerKits(getCustomerItems.inventoryDetails ?? [])
            setIsFetchingItems(false)
        }
        fetchData()
    }, [id])

    const goToKit = async (itemFulfillmentId: string, itemName: string) => {
        // get the kit serial number from the api
        setIsSearching(true)

        const kitsService = new KitsService()

        const { itemFulfillment } = await kitsService.getItemFulfillment(itemFulfillmentId)

        if (!itemFulfillment.readResponse?.record)
            throw new Error("No itemFulfillment found")

        const record = itemFulfillment.readResponse.record
        const kitSerialNumber = getIssueInventoryNumber(record, itemName)
        navigate('/admin/product/' + kitSerialNumber)
        setIsSearching(false)
    }

    const getIssueInventoryNumber = (jsonObj: any, itemName: string): string | null => {
        try {
            const itemList = jsonObj.itemList.item
            const targetItem = itemList.find((item: any) => item.itemName === itemName)

            if (targetItem && targetItem.inventoryDetail && targetItem.inventoryDetail.inventoryAssignmentList) {
                const inventoryAssignment = targetItem.inventoryDetail.inventoryAssignmentList.inventoryAssignment


                if (inventoryAssignment) {
                    // if it's an array, take the first one
                    if (Array.isArray(inventoryAssignment)) {
                        return inventoryAssignment[0].issueInventoryNumber.name
                    }

                    return inventoryAssignment.issueInventoryNumber.name
                }
            }

            // If the item with the given itemName or its inventory details are not found, return null.
            return null
        } catch (error) {
            // If there's any error while processing the JSON object, return null.
            return null
        }
    }

    const itemDetails = (item: any, index: any, itemFulfillmentId: string) => {
        const isKit = item?.itemName?.startsWith("100-999-00")
        return (
            <div className="basis-1/2 flex-grow max-w-[50%]" key={index}>
                <CardWrapper isNested extra='my-1 mx-2 min-w-[300px] h-100 '>

                    <SimpleList data={item} blackList={["internalId"]} />

                    <div className="flex justify-end my-2">
                        {/*// if is a kit, go to the kit page*/}
                        {isKit && (
                            <Button onClick={() => goToKit(itemFulfillmentId, item.itemName)} color="primary" rounded size={"sm"}>
                                Afficher le kit
                            </Button>
                        )
                        }
                    </div>
                </CardWrapper>
            </div>
        )
    }

    return (
        <div className="flex w-full flex-col gap-5 mt-6">
            <div className="flex w-full">

                {isSearching && (<Spinner />)}

                {customer?.email ? (
                    <div className="w-ful mt-3 flex h-fit flex-col gap-5">
                        <div className="col-span-7 lg:col-span-7 lg:mb-0 3xl:col-span-7">
                            <EntityDetailsWidget title={`Customer : ${customer.netsuiteCustomerId}`} description={customer?.email} listData={customer} blackList={["updatedAt"]} />
                        </div>

                        <div className="col-span-5 lg:col-span-5 lg:mb-0 3xl:col-span-5">
                            <div className='grid grid-flow-row auto-rows-max'>

                                <div className="col-span-5 lg:col-span-5 lg:mb-0 3xl:col-span-5">

                                    <Widget title="Items fulfillment" description="">
                                        {isFetchingItems && (<Spinner />)}
                                        {customerKits && customerKits.length > 0 &&
                                            customerKits.map((kit: any, index: number) => {
                                                return (
                                                    <>
                                                        <div className="flex flex-wrap">
                                                            {kit?.items && kit?.items.length > 0 &&
                                                                kit.items.map((item: any, index: number) => itemDetails(item, index, kit.internalId))
                                                            }
                                                        </div>
                                                        <hr className="my-5" />
                                                    </>
                                                )
                                            })
                                        }

                                        {!customerKits && <EmptyState title={"No items"} subtitle={`No data available`} />}

                                    </Widget>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (!isSearching && <EmptyState title={"No customer found"} subtitle={"Please search for a customer"} />)}
            </div>
        </div>
    )
}

export default CustomerOverview
