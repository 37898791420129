import React from 'react';
import Button from "../buttons/Button";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    children: React.ReactNode;
    loading?: boolean;
}

const BasicModal: React.FC<ModalProps> = ({isOpen, onClose, children, onConfirm, loading}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
            <div className="bg-white p-8 rounded shadow-md relative z-50">
                <div className="mb-4">{children}</div>

                <div className="flex justify-end gap-2">
                    <Button
                        color={"red-500"}
                        onClick={onConfirm}
                        isLoading={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color={"green-500"}
                        onClick={onConfirm}
                    >
                        Yes
                    </Button>
                </div>

            </div>
        </div>
    );
};

export default BasicModal;
