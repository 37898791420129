import React, { FC } from 'react'
import { MaterialReactTable, type MRT_Row } from 'material-react-table'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

interface Props {
    columns: any
    data: any
    name: string
}

const DashboardDatatable: FC<Props> = ({ data, columns, name = 'table' }) => {
    const csvOptions = {
        useKeysAsHeaders: true,
        filename: `${name}_export_${new Date().toLocaleDateString()}`,
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const csvConfig = mkConfig(csvOptions)

    const handleExportRows = (rows: MRT_Row<any>[]) => {
        const csv = generateCsv(csvConfig)(rows.map((row) => row.original))
        download(csvConfig)(csv)
    }

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data)
        download(csvConfig)(csv)
    }

    // @ts-ignore
    const topToolbarActions = ({ table }) => (
        <div className="flex flex-grow justify-end">
            <IconButton onClick={handleClick}>
                <FileDownloadIcon />
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={handleExportData}>Export All Data</MenuItem>
                <MenuItem
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={() => handleExportRows(table.getRowModel().rows)}>
                    Export Page Rows
                </MenuItem>

                <MenuItem
                    disabled={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                    }
                    onClick={() =>
                        handleExportRows(table.getSelectedRowModel().rows)
                    } >
                    Export Selected Rows
                </MenuItem>
            </Menu>
        </div>
    )

    return (
        <MaterialReactTable
            enableGlobalFilter
            columns={columns}
            enableRowSelection
            positionGlobalFilter="right"
            initialState={{ pagination: { pageSize: 50, pageIndex: 0 } }}
            muiTablePaginationProps={{
                rowsPerPageOptions: [50, 100],
                showFirstButton: false,
                showLastButton: false,
            }}
            data={data}
            renderTopToolbarCustomActions={topToolbarActions}
        />
    )
}

export default DashboardDatatable
