import React, { useMemo } from "react";
import useSWR from "swr";
import { EventService } from "../../../services/EventService";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import DatatableWrapper from "../../list/DatatableWrapper";
import { Event } from "../../../models/Event";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import Widget from "./Widget";

interface ProductDetectionsWidgetProps {
    sn: string;
    clientId: string;
    children?: React.ReactNode;
}


const ProductDetectionsWidget: React.FC<ProductDetectionsWidgetProps> = ({ sn, clientId,  children }) => {

    const eventService = new EventService();

    // Get the current date
    const currentDate = new Date()

    // Calculate the begin date as today minus 7 days
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 30)

    // Format the begin date and end date strings
    const beginDate = sevenDaysAgo.toISOString();
    const endDate = currentDate.toISOString();

    const handleShowSnapshot = (snapshotUrl: string) => {
        // go to snapshot page
        window.open(snapshotUrl, '_blank')
    }


    const imageLinkCell = (value: {
        renderedCellValue: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode>;
    }) => {
        return (
            // show an appropriate icon that shows the image when clicked
            <span >
                <PhotoCameraBackIcon
                    className="cursor-pointer text-blue-500"
                    onClick={() => handleShowSnapshot(value.renderedCellValue as string)}>
                </PhotoCameraBackIcon>
            </span>
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo<MRT_ColumnDef<Event>[]>(
        () => [
            {
                accessorKey: 'timestamp',
                header: 'Detected at',
            },
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'snap',
                header: 'Snapshot',
            },
            {
                accessorKey: 'camera',
                header: 'Camera',
            },
            {
                accessorKey: 'end',
                header: 'End',
            },
            {
                accessorKey: 'type',
                header: 'Type',
            },
            {
                accessorKey: 'duration',
                header: 'Duration (s)',
            },
            {
                accessorKey: 'imageUrl',
                header: 'Image',
                Cell: imageLinkCell
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    // fetch events
    const {
        data: eventsData,
        isLoading: eventsIsLoading,
        error: eventsError,
    } = useSWR(`/events/`, () => eventService.getByDateRange([sn], beginDate, endDate, clientId));

    // show a table with the list of events
    return (
        <Widget title="Events" description="Last 30 days events">
            <DatatableWrapper name={"Events"} isLoading={eventsIsLoading} data={eventsData}>
                <MaterialReactTable
                    columns={columns}
                    data={eventsData}
                    initialState={{ density: 'compact' }}
                    muiToolbarAlertBannerProps={
                        eventsError ? {
                            color: 'error',
                            children: 'Error loading data',
                        } : undefined
                    }
                />
            </DatatableWrapper>
        </Widget>
    )
}

export default ProductDetectionsWidget
