import Card from "components/card";
import React from "react";

interface EntityDetailsWidgetProps {
    title: string;
    description: string;
    children?: React.ReactNode;
}


const Widget: React.FC<EntityDetailsWidgetProps>  = ({title, description, children}) => {
    return (
        <Card extra={"w-full h-full p-3"} >
            {/* Header */}
            <div className="mt-2 mb-8 w-full">
                <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                    {title}
                </h4>
                <p className="mt-2 px-2 text-base text-gray-600">
                    {description}
                </p>
            </div>
            {/* Cards */}
            <div>
                {children}
            </div>
        </Card>
    );
};

export default Widget;
