import {BaseService, GetAllParams} from "./BaseService";
import mainApi from "../apis/mainApi";
import {Customer} from "../models/Customer";

export class CustomersService extends BaseService<Customer>{
    override readonly pathName = 'customers';
    private customerApi;


    constructor() {
        super( mainApi);
        this.customerApi = mainApi;
    }

    async getAll( params?:GetAllParams): Promise<Customer[] | null> {
        let res = await super.getAll(params) as any;

        if(res.customers)
            return res.customers;
    }

    async getCustomerItems(internalId:string): Promise<any | null> {
        try {
            const response = await this.customerApi.post(this.pathName + '/items-fulfillment',
                {
                    internalId: internalId
                });

            return response.data;
        } catch (error) {
            console.error(`Error fetching '${this.pathName}' data:`, error);
            return null;
        }
    }

    async getByInternalId(internalId: string): Promise<Customer | null> {
        try {
            const response = await this.customerApi.post(this.pathName,
                {
                    internalId: internalId
                });

            return response.data;
        } catch (error) {
            console.error(`Error fetching '${this.pathName}' data:`, error);
            return null;
        }
    }
}
