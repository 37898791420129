import { Link } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu'
import useSWR from "swr"

import Dropdown from "components/dropdown"
import logoImg from 'assets/img/logo.png'
import { UserService } from "../../services/UserService"

const Navbar = (props: {
  onOpenSidenav: () => void
  brandText: string
  secondary?: boolean | string
}) => {
  const { onOpenSidenav, brandText } = props

  const userService = new UserService()

  // fetch user data
  const {
    data: userData,
    isLoading: userIsLoading,
  } = useSWR(`/user/`, () => userService.get())


  return (
    <nav
      className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-darkPrimary">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white" href=" ">
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}/{" "}
            </span>
          </a>
          <Link className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white" to="#">
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link to="#" className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"  >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex flex-grow items-center justify-around gap-6 rounded-full bg-white px-6 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-6  xl:gap-6">
        <span className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden" onClick={onOpenSidenav}>
          <MenuIcon className="h-5 w-5" />
        </span>

        <div className="flex items-center gap-2">
          {!userIsLoading && (<p className="text-sm font-bold text-navy-700 dark:text-white">
            {userData && userData.name ? userData.name : "Admin"}
          </p>)}{" "}
        </div>

        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full object-contain cursor-pointer bg-darkPrimary"
              src={logoImg}
              alt="Admin"
            />
          }
          children={
            <div
              className="flex h-100 pb-3 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  {!userIsLoading && (<p className="text-sm font-bold text-navy-700 dark:text-white">
                    {userData && userData.name ? userData.name : "Admin"}
                  </p>)}{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <a href="/auth/logout" className="mt-3 text-sm font-medium  hover:text-accent">
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  )
}

export default Navbar
