import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'

import { ProductService } from '../../../services/ProductService'
import mainApi from '../../../apis/mainApi'
import EntityDetailsWidget from '../../../components/dashboard/widgets/EntityDetailsWidget'
import Widget from '../../../components/dashboard/widgets/Widget'
import CardWrapper from '../../../components/card/CardWrapper'
import SimpleList from '../../../components/list/SimpleList'
import EmptyState from '../../../components/list/EmptyState'
import DataList from './components/Data'
import ProductOverviewWidget from '../../../components/dashboard/widgets/ProductOverviewWidget'
import { KitsService } from '../../../services/KitsService'
import { Product } from '../../../models' // deprecated
import FormattedTextViewer from "../../../components/viewers/FormattedTextViewer"
import FoldableContainer from "../../../components/containers/FoldableContainer"
import Spinner from "../../../components/spinner/Spinner"
import ListItem from "../../../components/list/ListItem"
import ButtonConfirm from "../../../components/buttons/ButtonConfirm"
import ProductDetectionsWidget from "../../../components/dashboard/widgets/ProductDetectionsWidget"

const ProductOverview = () => {
    const productService = new ProductService(mainApi)

    // search bar input status
    const { id } = useParams<{ id: string }>()
    const [searchText] = React.useState<string>(id)
    const [isSearching, setIsSearching] = React.useState<boolean>(false)
    const [product, setProduct] = React.useState<any>({})
    const [kit, setKit] = React.useState<any>({})
    const [shouldFetchConfig, setShouldFetchConfig] = React.useState(false)

    const {
        data: productSimInfo,
        isLoading: productSimInfoLoading,
        error: productSimInfoError
    } = useSWR(`/product/${id}/sim`, () => productService.getSimInfo(id))

    const {
        data: productConfigData,
        isLoading: productConfigLoading,
        error: productConfigError
    } = useSWR(shouldFetchConfig ? `/product/${id}/config` : null, () => productService.getConfig(id))

    const [companyItemsBlacklist] =
        React.useState<any>(['Created By', '_id'])

    const [vehicleItemsBlacklist] =
        React.useState<any>([
            'Created By',
            '_id',
            'company_custom_company',
            'worksite_custom_worksite',
            'category_custom_vehicle_category',
            'brand_custom_vehicle_brand',
        ])

    const [worksiteItemsBlacklist] =
        React.useState<any>([
            'Created By',
            '_id',
            'company_custom_company',
            'worksite_custom_worksite',
            'category_custom_vehicle_category',
            'id_text',
        ])


    React.useEffect(() => {
        const onSearchClick = async () => {
            setIsSearching(true)

            // fetch the product data to get the kit it
            const productService: ProductService = new ProductService(mainApi)
            const kitService: KitsService = new KitsService()
            // IMPORTANT TODO Useless for now : data from bubble to change
            const currentProduct = await productService.getBySN(searchText)
            setIsSearching(false)

            // IMPORTANT TODO create a service in the back that get both
            let kitSerialNumber = null

            if (currentProduct.SerialNumber.startsWith('9'))
                kitSerialNumber = currentProduct.SerialNumber

            if (currentProduct.sn_kit_text)
                kitSerialNumber = currentProduct.sn_kit_text
            else {
                if (currentProduct.kits && currentProduct.kits.length > 0)
                    kitSerialNumber = currentProduct.kits[0]['serialNumber']
            }

            let mainProduct = currentProduct

            let productKit = null
            // get the kit from the api
            if (kitSerialNumber)
                productKit = await kitService.getBySN(kitSerialNumber as string) as any

            // get the main product from the kit
            if (productKit && productKit.kit && productKit.kit['serialNumberPU']) {
                setKit(productKit.kit)
                mainProduct = await productService.getBySN(productKit.kit.serialNumberPU)
            }

            setProduct(mainProduct)
            setIsSearching(false)
        }

        onSearchClick()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSerialNumberType = (serialNumber: string) => {
        if (!serialNumber) return 'Product'

        // if it starts with 0 then it is an UT
        if (serialNumber.startsWith('0')) {
            return 'UT'
        }

        // if it starts with 1 then it is a Camera
        if (serialNumber.startsWith('1')) {
            return 'Camera'
        }

        // if it starts with 9 then it is a Kit
        if (serialNumber.startsWith('9')) {
            return 'Kit'
        }
    }

    const getKitProducts = () => {
        const products = [product]
        if (!kit.products) return products
        // add the kit products without duplicates
        kit.products.forEach((kitProduct: Product) => {
            if (
                !products.find(
                    (product: Product) =>
                        product.SerialNumber === kitProduct.SerialNumber
                )
            )
                products.push(kitProduct)
        })
        return products
    }

    const enableSim = async (value: boolean) => {
        const productService: ProductService = new ProductService(mainApi)
        await productService.simEnable(product.SerialNumber, value)
        await mutate(`/product/${id}/sim`)
    }

    const getTitle = () => {
        // TODO refactor : the js test and and the jsx test is redundant
        switch (getSerialNumberType(searchText)) {
            case 'UT':
                return (
                    <span>{getSerialNumberType(searchText)} : <span className="text-secondary">{searchText}</span> du kit: <span
                        className="text-secondary">{kit?.serialNumber}</span> selectionné</span>
                )
            case 'Camera':
                return (
                    <span>{getSerialNumberType(searchText)} : <span className="text-secondary">{searchText}</span> du kit: <span
                        className="text-secondary">{kit?.serialNumber}</span> selectionné</span>
                )
            case 'Kit':
                return (<span>
                    {getSerialNumberType(searchText)} : <span className="text-secondary">{searchText}</span> selectionné
                </span>)
            default:
                return (<span>
                    {getSerialNumberType(searchText)} :
                    <span className="text-secondary">{searchText}</span> du kit:
                    <span className="text-secondary">{kit?.serialNumber}</span> sélectionné
                </span>)
        }
    }

    return (
        <div className="mt-6 flex w-full flex-col gap-5">
            <div className="flex w-full items-center justify-center">
                {/*<div className="lg:grid lg:grid-cols-2 relative  h-[62px]   rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1  xl:gap-2">*/}
                {/*<SearchBar onChange={onSearchBarChange} placeholder={'0-0711-2228-0029'} disabled={isSearching}/>*/}
                {/*<Button onClick={onSearchClick} color="primary" rounded disabled={isSearching} isLoading={isSearching}>*/}
                {/*// if isSearching then show a spinner loading bar*/}
                {isSearching && (
                    <div className="flex items-center justify-center">
                        <svg aria-hidden="true"
                            className="mr-2 inline h-6 w-6 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"  >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
            </div>
            <div className="flex flex-col gap-5">
                <h4 className="px-2 text-2xl text-navy-700 dark:text-white">
                    {getTitle()}
                </h4>

                <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
                    {/*if there are products in the kits, show them*/}
                    {getKitProducts().map((cproduct) => (
                        <div className="col-span-6 lg:col-span-6 lg:mb-0 3xl:col-span-6">
                            <ProductOverviewWidget product={cproduct}>
                                {cproduct.SerialNumber?.startsWith('0') && (
                                    <FoldableContainer title={"Configuration"}
                                        onClick={() => setShouldFetchConfig(true)}>
                                        {productConfigLoading && <Spinner />}
                                        {
                                            productConfigError && (
                                                <EmptyState title={"No data"}
                                                    subtitle={`No config available for this product`} />
                                            )
                                        }

                                        {productConfigData && (<div className="grid grid-cols-2 gap-4">
                                            <div className=" p-1 col-span-2">
                                                <ListItem title={"config.ini"}>
                                                    <FormattedTextViewer iniContent={productConfigData.configuration} />
                                                </ListItem>
                                            </div>
                                            <div className=" p-1 col-span-2">
                                                {productConfigData && Object.keys(productConfigData).length > 0 ? (
                                                    <div className="grid grid-cols-2 gap-4 px-2">
                                                        <SimpleList data={productConfigData} blackList={["configuration"]} />
                                                        {productConfigData.shockInfo ?
                                                            <SimpleList data={productConfigData.shockInfo} blackList={["verticalThreshold", "horizontalThreshold"]} /> :
                                                            <p>no shock info</p>}
                                                    </div>
                                                ) : (<EmptyState title={"No data"} subtitle={`No data available for this product`} />)}
                                            </div>
                                        </div>)}
                                    </FoldableContainer>
                                )
                                }
                            </ProductOverviewWidget>
                        </div>
                    ))}

                    {/*Product detections*/}
                    <div className="col-span-12 lg:col-span-12 lg:mb-0 3xl:col-span-12">
                        {product.company && <ProductDetectionsWidget sn={id} clientId={product.company._id} />}
                    </div>

                    <div className="col-span-6 lg:col-span-6 lg:mb-0 3xl:col-span-6">
                        <EntityDetailsWidget
                            title={'Company'}
                            description={''}
                            listData={product.company ?? {}}
                            blackList={companyItemsBlacklist}
                        />
                    </div>

                    <div className="col-span-6 lg:col-span-6 lg:mb-0 3xl:col-span-6">
                        <EntityDetailsWidget
                            title={'Vehicle'}
                            description={''}
                            listData={product.vehicle ?? {}}
                            blackList={vehicleItemsBlacklist}
                        />
                    </div>

                    <div className="col-span-6 lg:col-span-6 lg:mb-0 3xl:col-span-6">
                        <EntityDetailsWidget
                            title={'Worksite'}
                            description={''}
                            listData={product.worksite ?? {}}
                            blackList={worksiteItemsBlacklist}
                        />
                    </div>

                    {/*SIM WIDGET*/}
                    <div className="col-span-6 lg:col-span-6 lg:mb-0 3xl:col-span-6">
                        <Widget title='Sim' description="">
                            {productSimInfoError &&
                                (<EmptyState title={"No sim found"} />)
                            }

                            {productSimInfo && Object.keys(productSimInfo).length && (
                                <div>
                                    <div className="grid grid-cols-2 gap-4 px-2">
                                        <div className="col-span-2">
                                            <div className="grid grid-cols-2 gap-4 ">

                                                <ListItem title={"Status"}>
                                                    <div className="flex flex-col gap-2">
                                                        <span
                                                            className={`px-2 py-1 rounded-full text-white text-sm font-bold ${productSimInfo.status === 'ACTIVATED' ? 'bg-green-500' : 'bg-red-500'}`}>
                                                            {productSimInfo.status}
                                                        </span>
                                                    </div>
                                                </ListItem>

                                                <ListItem title={"Actions"}>
                                                    <ButtonConfirm
                                                        bodyText={`Are you sure you want to ${productSimInfo.status === 'ACTIVATED' ? 'deactivate' : 'activate'} this SIM?`}
                                                        color={productSimInfo.status === 'ACTIVATED' ? 'red-500' : 'primary'}
                                                        onClick={() => enableSim(true)}
                                                        isLoading={productSimInfoLoading}
                                                    >
                                                        {
                                                            productSimInfo.status === 'ACTIVATED' ? 'Deactivate SIM' : 'Activate SIM'
                                                        }
                                                    </ButtonConfirm>
                                                </ListItem>
                                            </div>
                                        </div>
                                        <SimpleList data={productSimInfo} />
                                    </div>
                                </div>)
                            }
                            {productSimInfoLoading && (<Spinner />)}
                        </Widget>
                    </div>
                    {/*END SIM WIDGET*/}
                </div>

                <div className="flex flex-col flex-wrap gap-6">
                    <Widget title="Kits" description="">
                        {product.kits &&
                            product.kits.length > 0 &&
                            product.kits.map((kit: any, index: number) => {
                                return (
                                    <CardWrapper isNested extra="my-6">
                                        <div className="flex flex-col gap-5">
                                            <h4 className="text-navy-00 px-2 text-xl font-bold dark:text-white">
                                                {`Kit : ${kit.serialNumber}`}
                                            </h4>
                                            <hr />
                                            <div className="grid grid-cols-2 gap-4 px-6">
                                                <SimpleList data={kit} blackList={['id', 'updatedAt', 'isLast', 'createdAt']}/>
                                            </div>

                                            {kit.customer && (
                                                <div>
                                                    <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                                                        {`Customer`}
                                                    </h4>
                                                    <hr />
                                                    <div className="grid grid-cols-2 gap-4 px-6 py-6">
                                                        <SimpleList
                                                            data={
                                                                kit.customer
                                                            }
                                                            blackList={[
                                                                'id', 'updatedAt'
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {kit.data && (
                                                <div className="px-4">
                                                    <h4 className=" text-xl font-bold text-navy-700 dark:text-white">
                                                        {`Items`}
                                                    </h4>
                                                    <DataList object={kit.data} />
                                                </div>
                                            )}
                                        </div>
                                    </CardWrapper>)
                            })}
                    </Widget>
                </div>
            </div>
        </div>
    )
}

export default ProductOverview
