import React from 'react';

interface Item {
    pn: string;
    sn?: string;
    quantity: number;
}

interface ObjectListProps {
    object: { [key: string]: Item };
}

const DataList: React.FC<ObjectListProps> = ({ object }) => {
    return (
        <div className="flex flex-wrap gap-5">

                {Object.entries(object).map(([key, item]) => (
                    <div key={key} className="p-2">
                        <h2 className="text-lg font-bold">{key}</h2>
                        <p>Part Number: {item.pn}</p>
                        {item.sn && <p>Serial Number: {item.sn}</p>}
                        <p>Quantity: {item.quantity}</p>
                    </div>
                ))}
        </div>
    );
};

export default DataList;
