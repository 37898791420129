import React, {useEffect, useMemo, useState} from 'react';
import {CustomersService} from "../../../services/CustomersService";
import {Customer} from "../../../models/Customer";
import DatatableWrapper from "../../../components/list/DatatableWrapper";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    MRT_SortingState
} from "material-react-table";
import {GetAllParams} from "../../../services/BaseService";

const CustomersOverview: React.FC = () => {
    const [customersList, setCustomersList] = useState<Customer[]>([]);
    const [isSearching, setIsSearching] = React.useState<boolean>(true);

    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [searchId, setSearchId] = useState<string>(null);

    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);


    //store pagination state in your own state
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100, //customize the default page size
    });


    const internalCustomerIdCell = (value: { renderedCellValue: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode>; }) => {
        return (
            <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleSerialNumberClick(value.renderedCellValue as string)}
            >
                      {value.renderedCellValue}
                    </span>
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo<MRT_ColumnDef<Customer>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'netsuiteCustomerId',
                header: 'Netsuite Customer ID',
            },
            {
                accessorKey: 'netsuiteInternalId',
                header: 'Netsuite Internal ID',
                Cell: internalCustomerIdCell
            },
            {
                accessorKey: 'createdAt',
                header: 'Created at',
            }

        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect( () => {
        setIsSearching(true);

        fetchData(searchId).then(
            () => {
                setIsSearching(false);

                setIsError(false);
                setIsSearching(false);
                setIsRefetching(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (searchId:string) => {
        setIsRefetching(true);

        const customerService: CustomersService = new CustomersService();

        let params:GetAllParams = {
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageIndex+1,
            searchId: searchId,
            globalFilter: globalFilter,
        }

        const customers: any = await customerService.getAll(params);

        try {
            setCustomersList(customers.result ?? []);
            setRowCount(customers.pagination.totalCount);
            setSearchId(customers.pagination.searchId);
        } catch (error) {
            setIsError(true);
            setIsSearching(false);

            console.error(error);
            return;
        }

        setIsRefetching(false);

    };

    const handleSerialNumberClick = (serialNumber: string) => {
        // go to the page product details
        window.location.href = `/admin/customers/${serialNumber}`;
    };


    //if you want to avoid useEffect, look at the React Query example instead
    useEffect(() => {
        fetchData(searchId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);



    return (
        <div className="container mx-auto mt-6">
            {/*<div className="flex w-full justify-end items-center py-6">*/}
            {/*    <div className="lg:grid lg:grid-cols-2 relative  h-[62px]   rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[370px] md:flex-grow-0 md:gap-1  xl:gap-2">*/}

            {/*        <SearchBar onChange={onSearchBarChange} placeholder={'0-0711-2228-0029'} disabled={isSearching}/>*/}
            {/*        <Button onClick={onSearchClick} color="primary" rounded disabled={isSearching} isLoading={isSearching}>*/}
            {/*            Search*/}
            {/*        </Button >*/}

            {/*    </div>*/}
            {/*</div>*/}


            <DatatableWrapper name={"Products"} isLoading={isSearching} data={customersList} >
                    <MaterialReactTable
                        columns={columns}
                        data={customersList}
                        manualFiltering
                        manualPagination
                        enableGlobalFilter
                        enableColumnFilters={false}
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: 'Error loading data',
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        onGlobalFilterChange={setGlobalFilter}
                        onSortingChange={setSorting}
                        rowCount={rowCount}
                        state={{
                            globalFilter,
                            isLoading: isRefetching,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                        }}
                    />
            </DatatableWrapper>
        </div>
    );
};

export default CustomersOverview;
