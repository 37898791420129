import { AxiosInstance } from "axios"

export interface GetAllParams {
    [key: string]: string | number
}

export abstract class BaseService<T> {
    abstract readonly pathName: string
    protected readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    async getAll(params?: GetAllParams): Promise<T[] | null> {
        try {
            const response = await this.api.get(this.pathName,
                {
                    params: params
                }
            )

            return response.data
        } catch (error) {
            console.error(`Error fetching '${this.pathName}' data:`, error)
            return null
        }
    }

    protected handleError(error: any): void {
        console.error(`API ${this.pathName} Fetching Error:`, error)
        // throw new Error(`API Error: ${error.message || 'Unknown Error'}`)
    }
}
