import { BaseService } from "./BaseService"
import mainApi from "../apis/mainApi"
import { Event } from "../models/Event"

export class EventService extends BaseService<Event> {
    override readonly pathName = 'events'

    constructor() {
        super(mainApi)
    }

    async getByDateRange(SNs: string[], begin: string, end: string, clientId: string): Promise<Event[]> {
        try {
            const response = await this.api.post(this.pathName, { sn: SNs, begin, end, clientId })
            return response.data.events || []
        } catch (error) {
            this.handleError(error)
            return []
        }
    }
}