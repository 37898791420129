import React, {useState} from 'react';
import BasicModal from "../modals/BasicModal";
import Button from "./Button";

interface ButtonProps {
    onClick: () => void;
    className?: string;
    color?: string;
    rounded?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
    size?: 'sm' | 'md' | 'lg';
    bodyText?: string;
}

const ButtonConfirm: React.FC<ButtonProps> = ({
                                                  onClick,
                                                  className,
                                                  color,
                                                  rounded,
                                                  isLoading,
                                                  disabled,
                                                  children,
                                                  size,
                                                  bodyText
                                              }) => {
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        showModal ? setShowModal(false) : setShowModal(true);

    };

    const onModalConfirm = () => {
        onClick();
        setShowModal(false);

    }

    const onModalCancel = () => {
        setShowModal(false);
    }

    const buttonClasses = `bg-${color} hover:bg-${color} text-${size || "md"} text-white font-bold py-2 px-4 ${
        rounded ? 'rounded-full' : 'rounded'
    } ${className}`;

    return (
        <div>
            <BasicModal isOpen={showModal} onConfirm={onModalConfirm} onClose={onModalCancel} loading={isLoading}>
                {bodyText}
            </BasicModal>

            <Button onClick={handleClick} className={buttonClasses} color={color} rounded={rounded}
                    isLoading={isLoading} disabled={disabled} size={size}>
                {children}
            </Button>
        </div>
    );
};

export default ButtonConfirm;
