import SimpleList from "../../list/SimpleList";
import React from "react";
import EmptyState from "../../list/EmptyState";
import Widget from "./Widget";

interface EntityDetailsWidgetProps {
    title: string;
    description: string;
    listData: any;
    blackList?: string[];
}


const EntityDetailsWidget: React.FC<EntityDetailsWidgetProps> = ({title, description, listData, blackList = []}) => {
    return (
        <Widget description={description} title={title}>
            {/* Cards */}
            {listData && Object.keys(listData).length > 0 ? (<div className="grid grid-cols-2 gap-4 px-2">
                <SimpleList data={listData} blackList={blackList}/>
            </div>) : (<EmptyState title={"No data"} subtitle={`No data available for ${title}`}/>)}
        </Widget>
    );
};

export default EntityDetailsWidget;
