import React from "react"
import { Product } from "../../../models/Product"
import Card from "../../card"
import SimpleList from "../../list/SimpleList"
import EmptyState from "../../list/EmptyState"

// deprecated
interface ProductOverviewWidgetProps {
    product: Product
    children?: React.ReactNode
}

// deprecated
const ProductOverviewWidget: React.FC<ProductOverviewWidgetProps> = ({ product, children }) => {
    const [productItemsBlacklist] =
        React.useState<any>(["id", "sn_text", "name_text", "order_id_text", "Created By", "description_text", "order_id1_text", "worksite_custom_worksite" +
            "worksite_custom_worksite", "_id", "vehicle_custom_vehicle", "company_custom_company", "worksite_custom_worksite", "type"])

    const getSerialNumberType = (serialNumber: string) => {
        if (!serialNumber)
            return 'UT'

        // if it starts with 0 then it is an UT
        if (serialNumber.startsWith('0')) {
            return 'UT'
        }

        // if it starts with 1 then it is a Camera
        if (serialNumber.startsWith('1')) {
            return 'Camera'
        }

        // if it starts with 9 then it is a Kit
        if (serialNumber.startsWith('9')) {
            return 'Kit'
        }
    }

    return (

        <Card extra={"w-full h-full p-3"}>
            {/* Header */}
            <div className="mt-2 mb-8 w-full">
                <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                    {`${getSerialNumberType(product.SerialNumber)}: ${product.SerialNumber}`}
                </h4>
            </div>
            {/* Cards */}
            {product && Object.keys(product).length > 0 ? (<div className="grid grid-cols-2 gap-4 px-2">
                <SimpleList data={product} blackList={productItemsBlacklist} />
                {/* Children */}
                <div className="col-span-2">
                    {children}
                </div>
            </div>) : (<EmptyState title={"No data"} subtitle={`No data available for this product`} />)}

        </Card>

    )

}

export default ProductOverviewWidget
