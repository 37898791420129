/**
 * @file msal.ts
 * @brief MSAL instance for authentication
 *
 */
import {ProtocolMode, PublicClientApplication} from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
        authority: process.env.REACT_APP_AZURE_AUTHORITY,
        protocolMode: ProtocolMode.AAD,
        redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    }
};

const MSALObj = new PublicClientApplication(msalConfig);

await MSALObj.initialize();

export default MSALObj;

