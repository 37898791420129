import React from "react"

/**
 * ListItem component props
 * @interface ListItemProps
 * @property {string} title - The title of the list item
 * @property {string} value - The value of the list item
 *
 */
interface ListItemProps {
    title: string
    children?: React.ReactNode
}


const ListItem:React.FC<ListItemProps> = ({title, children}) => {
    return (
        <>
        <div className="flex flex-col items-start justify-center">
            <p className="text-sm text-gray-600">{title}</p>
            <p className="text-base font-medium text-navy-700 dark:text-white  break-all">
                {children ? children : "N/A"}
            </p>
        </div>
        </>
    )
}

export default ListItem
