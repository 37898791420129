import { BaseService } from "./BaseService"
import mainApi from "../apis/mainApi"
import { User } from "../models/User"

export class UserService extends BaseService<User>{
    override readonly pathName = 'user'


    constructor() {
        super(mainApi)
    }

    async get(): Promise<User | null> {
        try {
            const response = await this.api.get(this.pathName)

            return response.data
        } catch (error) {
            console.error(`Error fetching '${this.pathName}' data:`, error)
            return null
        }
    }

}
