import React from 'react'

import ListItem from "./ListItem"
import ValueRenderer from "../data/ValueRenderer"

interface SimpleListProps {
    data: any
    blackList?: string[]
}

const SimpleList: React.FC<SimpleListProps> = ({ data, blackList = [] }) => {
    const isBlackListed = (key: string) => {
        return blackList && blackList.includes(key)
    }

    const stringifiedData = (data: any) => {
        let res = JSON.stringify(data)
        res = res.replace(/"/g, '') // remove " from string
        return res
    }
    const formatKey = (key: string): string => {
        const keyMappings: { [key: string]: string } = {
            "status": "Statut",
            "date": "Date",
            "productedAt": "Date de production",
            "createdAt": "Date de création",
            "operator": "Opérateur",
            "serialNumber": "Numéro de serie du KIT",
            "SerialNumber": "Numéro de serie du KIT",
            "productNumber": "Part Number KIT",
            "serialNumberPU": "Numéro de série de l'UT",
            "productNumberPU": "Part Number PU",
            "name": "Nom du client",
            "netsuiteCustomerId": "ID netSuite du client"
        };
        return keyMappings[key] || key;
    };

    return <>
        {Object.keys(data).map((key) => {
            if (data[key] !== "" && data[key] !== null && typeof data[key] !== "object" && !Array.isArray(data[key]) && !isBlackListed(key)) {
                return (
                    <ListItem key={key} title={formatKey(key)}>
                        <ValueRenderer value={stringifiedData(data[key])} attributeKey={key} />
                    </ListItem>
                )
            }
            return []
        })}
    </>
}

export default SimpleList
